// ==============================
// Variables
// ==============================

// ========== Global ========== //
// Font and Line Height
$global-font-family: system-ui, -apple-system, BlinkMacSystemFont, PingFang SC, Microsoft YaHei UI, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !default;
$global-font-size: 16px;
$global-font-weight: 400;
$global-line-height: 1.5rem;

// Radius of the border
$global-border-radius: 5px !default;

// Color of the background
$global-background-color: #ffffff !default;
$global-background-color-dark: #292a2e !default;

// Color of the text
$global-font-color: #161209 !default;
$global-font-color-dark: #b1b1ba !default;

// Color of the secondary text
$global-font-secondary-color: #b1b1ba !default;
$global-font-secondary-color-dark: #909092 !default;

// Color of the link
$global-link-color: #161209 !default;
$global-link-color-dark: #b1b1ba !default;

// Color of the hover link
$global-link-hover-color: #2983bb !default; // 潮蓝
$global-link-hover-color-dark: #fff !default;

// Color of the border
$global-border-color: #f0f0f0 !default;
$global-border-color-dark: #363636 !default;
// ========== Global ========== //

// ========== Scrollbar ========== //
// Color of the scrollbar
$scrollbar-color: #87878d !default;

// Color of the hover scrollbar
$scrollbar-hover-color: #b1b1ba !default;
// ========== Scrollbar ========== //

// ========== Selection ========== //
// Color of the selected text
$selection-color: rgba(53, 166, 247, 0.25) !default;
$selection-color-dark: rgba(50, 112, 194, 0.4) !default;
// ========== Selection ========== //

// ========== Header ========== //
// Height of the header
$header-height: 3.5rem !default;

// Color of the header background
$header-background-color: #f8f8f8 !default;
$header-background-color-dark: #252627 !default;

// Font style of the header title
$header-title-font-family: $global-font-family !default;
$header-title-font-size: 1.5rem !default;

// Color of the active menu item
$menu-active-color: #161209 !default;
$menu-active-color-dark: #fff !default;

// Color of the search background
$search-background-color: #e9e9e9 !default;
$search-background-color-dark: #363636 !default;
// ========== Header ========== //

// ========== Single Content ========== //
// Font size of the TOC
$toc-title-font-size: 1.2rem !default;
$toc-content-font-size: 1rem !default;

// Color of the single link
$single-link-color: #2376b7 !default; // 花青
$single-link-color-dark: #1781b5 !default; // 釉蓝

// Color of the hover single link
$single-link-hover-color: #ea517f !default; // 莲瓣红
$single-link-hover-color-dark: #619ac3 !default; // 羽扇豆蓝

// Color of the table background
$table-background-color: #fff !default;
$table-background-color-dark: #272c34 !default;

// Color of the table thead
$table-thead-color: #ededed !default;
$table-thead-color-dark: #20252b !default;

// Color of the blockquote
$blockquote-color: #697681 !default; 
$blockquote-color-dark: #9ba3aa !default;
// ========== Single Content ========== //

// ========== Pagination ========== //
// Color of the link in pagination
$pagination-link-color: #bfbfbf !default;
$pagination-link-color-dark: #b1b1ba !default;

// Color of the hover link in pagination
$pagination-link-hover-color: #000 !default;
$pagination-link-hover-color-dark: #fff !default;
// ========== Pagination ========== //

// ========== Code ========== //
// Color of the code
$code-color: #24292f !default;
$code-color-dark: #adbac7 !default;

// Color of the code background
$code-background-color: #f6f8fa !default;
$code-background-color-dark: #2d333b !default;

$code-error-color: #dc3545 !default;

// Color of the hightlight code
$code-hightlight-color: #fff8c5 !default;
$code-hightlight-color-dark: rgba(174, 124, 20, 0.15) !default;

$code-info-color: #9c9c9c !default;
$code-info-color-dark: #b1b0b0 !default;

// Font size of the code
$code-font-size: 0.875rem !default;

// Font family of the code
$code-font-family: Source Code Pro, Menlo, Consolas, Monaco, monospace, $global-font-family !default;

// Code type map
$code-type-map: (
  // Custom code type
  language-bash: 'Bash',
  language-c: 'C',
  language-cs: 'C#',
  language-cpp: 'C++',
  language-clojure: 'Clojure',
  language-coffeescript: 'CoffeeScript',
  language-css: 'CSS',
  language-dart: 'Dart',
  language-diff: 'Diff',
  language-erlang: 'Erlang',
  language-go: 'Go',
  language-go-html-template: 'Go HTML Template',
  language-groovy: 'Groovy',
  language-haskell: 'Haskell',
  language-html: 'HTML',
  language-http: 'HTTP',
  language-xml: 'XML',
  language-java: 'Java',
  language-js: 'JavaScript',
  language-javascript: 'JavaScript',
  language-json: 'JSON',
  language-kotlin: 'Kotlin',
  language-latex: 'LaTeX',
  language-less: 'Less',
  language-lisp: 'Lisp',
  language-lua: 'Lua',
  language-makefile: 'Makefile',
  language-markdown: 'Markdown',
  language-matlab: 'Matlab',
  language-objectivec: 'Objective-C',
  language-php: 'PHP',
  language-perl: 'Perl',
  language-python: 'Python',
  language-r: 'R',
  language-ruby: 'Ruby',
  language-rust: 'Rust',
  language-scala: 'Scala',
  language-scss: 'Scss',
  language-shell: 'Shell',
  language-sql: 'SQL',
  language-swift: 'Swift',
  language-tex: 'TeX',
  language-toml: 'TOML',
  language-ts: 'TypeScript',
  language-typescript: 'TypeScript',
  language-vue: 'Vue',
  language-yml: 'YAML',
  language-yaml: 'YAML'
) !default;

// Color map of the code highlight
$code-highlight-color-map: (
  /* Error */ 'err': $code-error-color,
  /* Keyword */ 'k': #cf222e,
  /* KeywordConstant */ 'kc': #0550ae,
  /* KeywordDeclaration */ 'kd': #cf222e,
  /* KeywordNamespace */ 'kn': #cf222e,
  /* KeywordPseudo */ 'kp': #cf222e,
  /* KeywordReserved */ 'kr': #cf222e,
  /* KeywordType */ 'kt': #cf222e,
  /* Name */ 'n': $code-color,
  /* NameAttribute */ 'na': #0550ae,
  /* NameBuiltin */ 'nb': #0550ae,
  /* NameBuiltinPseudo */ 'bp': $code-color,
  /* NameClass */ 'nc': #8250df,
  /* NameConstant */ 'no': #cf222e,
  /* NameDecorator */ 'nd': #8250df,
  /* NameEntity */ 'ni': #0550ae,
  /* NameException */ 'ne': #0550ae,
  /* NameFunction */ 'nf': #0550ae,
  /* NameFunctionMagic */ 'fm': #0550ae,
  /* NameLabel */ 'nl': #0550ae,
  /* NameNamespace */ 'nn': #8250df,
  /* NameOther */ 'nx': #0550ae,
  /* NameProperty */ 'py': #0550ae,
  /* NameTag */ 'nt': #116329,
  /* NameVariable */ 'nv': #953800,
  /* NameVariableClass */ 'vc': #0550ae,
  /* NameVariableGlobal */ 'vg': #0550ae,
  /* NameVariableInstance */ 'vi': #0550ae,
  /* NameVariableMagic */ 'vm': #0550ae,
  /* Literal */ 'l': #0550ae,
  /* LiteralDate */ 'ld': #0a3069,
  /* LiteralString */ 's': #0a3069,
  /* LiteralStringAffix */ 'sa': #0a3069,
  /* LiteralStringBacktick */ 'sb': #0a3069,
  /* LiteralStringChar */ 'sc': #0a3069,
  /* LiteralStringDelimiter */ 'dl': #0a3069,
  /* LiteralStringDoc */ 'sd': #0a3069,
  /* LiteralStringDouble */ 's2': #0a3069,
  /* LiteralStringEscape */ 'se': #0a3069,
  /* LiteralStringHeredoc */ 'sh': #0a3069,
  /* LiteralStringInterpol */ 'si': #0a3069,
  /* LiteralStringOther */ 'sx': #0a3069,
  /* LiteralStringRegex */ 'sr': #0a3069,
  /* LiteralStringSingle */ 's1': #0a3069,
  /* LiteralStringSymbol */ 'ss': #0a3069,
  /* LiteralNumber */ 'm': #0550ae,
  /* LiteralNumberBin */ 'mb': #0550ae,
  /* LiteralNumberFloat */ 'mf': #0550ae,
  /* LiteralNumberHex */ 'mh': #0550ae,
  /* LiteralNumberInteger */ 'mi': #0550ae,
  /* LiteralNumberIntegerLong */ 'il': #0550ae,
  /* LiteralNumberOct */ 'mo': #0550ae,
  /* Operator */ 'o': #cf222e,
  /* OperatorWord */ 'ow': #cf222e,
  /* Punctuation */ 'p': $code-color,
  /* Comment */ 'c': #6e7781,
  /* CommentHashbang */ 'ch': #6e7781,
  /* CommentMultiline */ 'cm': #6e7781,
  /* CommentSingle */ 'c1': #6e7781,
  /* CommentSpecial */ 'cs': #6e7781,
  /* CommentPreproc */ 'cp': #0550ae,
  /* CommentPreprocFile */ 'cpf': #6e7781,
  /* Generic */ 'g': $code-color,
  /* GenericDeleted */ 'gd': #82071e,
  /* GenericEmph */ 'ge': $code-color,
  /* GenericError */ 'gr': $code-error-color,
  /* GenericHeading */ 'gh': $code-color,
  /* GenericInserted */ 'gi': #116329,
  /* GenericOutput */ 'go': $code-color,
  /* GenericPrompt */ 'gp': $code-color,
  /* GenericStrong */ 'gs': $code-color,
  /* GenericSubheading */ 'gu': $code-color,
  /* GenericTraceback */ 'gt': $code-error-color,
  /* GenericUnderline */ 'gl': $code-color,
  /* TextWhitespace */ 'w': $code-color,
  /* Other */ 'x': $code-color
) !default;
$code-highlight-color-map-dark: (
  /* Error */ 'err': $code-error-color,
  /* Keyword */ 'k': #f47067,
  /* KeywordConstant */ 'kc': #6cb6ff,
  /* KeywordDeclaration */ 'kd': #f47067,
  /* KeywordNamespace */ 'kn': #f47067,
  /* KeywordPseudo */ 'kp': #f47067,
  /* KeywordReserved */ 'kr': #f47067,
  /* KeywordType */ 'kt': #f47067,
  /* Name */ 'n': $code-color-dark,
  /* NameAttribute */ 'na': #6cb6ff,
  /* NameBuiltin */ 'nb': #dcbdfb,
  /* NameBuiltinPseudo */ 'bp': $code-color-dark,
  /* NameClass */ 'nc': #dcbdfb,
  /* NameConstant */ 'no': #6cb6ff,
  /* NameDecorator */ 'nd': #dcbdfb,
  /* NameEntity */ 'ni': #6cb6ff,
  /* NameException */ 'ne': #6cb6ff,
  /* NameFunction */ 'nf': #dcbdfb,
  /* NameFunctionMagic */ 'fm': #6cb6ff,
  /* NameLabel */ 'nl': #6cb6ff,
  /* NameNamespace */ 'nn': #f47067,
  /* NameOther */ 'nx': #dcbdfb,
  /* NameProperty */ 'py': #6cb6ff,
  /* NameTag */ 'nt': #8ddb8c,
  /* NameVariable */ 'nv': #f69d50,
  /* NameVariableClass */ 'vc': #6cb6ff,
  /* NameVariableGlobal */ 'vg': #6cb6ff,
  /* NameVariableInstance */ 'vi': #6cb6ff,
  /* NameVariableMagic */ 'vm': #6cb6ff,
  /* Literal */ 'l': #6cb6ff,
  /* LiteralDate */ 'ld': #96d0ff,
  /* LiteralString */ 's': #96d0ff,
  /* LiteralStringAffix */ 'sa': #96d0ff,
  /* LiteralStringBacktick */ 'sb': #96d0ff,
  /* LiteralStringChar */ 'sc': #96d0ff,
  /* LiteralStringDelimiter */ 'dl': #96d0ff,
  /* LiteralStringDoc */ 'sd': #96d0ff,
  /* LiteralStringDouble */ 's2': #96d0ff,
  /* LiteralStringEscape */ 'se': #96d0ff,
  /* LiteralStringHeredoc */ 'sh': #96d0ff,
  /* LiteralStringInterpol */ 'si': #96d0ff,
  /* LiteralStringOther */ 'sx': #96d0ff,
  /* LiteralStringRegex */ 'sr': #96d0ff,
  /* LiteralStringSingle */ 's1': #96d0ff,
  /* LiteralStringSymbol */ 'ss': #96d0ff,
  /* LiteralNumber */ 'm': #6cb6ff,
  /* LiteralNumberBin */ 'mb': #6cb6ff,
  /* LiteralNumberFloat */ 'mf': #6cb6ff,
  /* LiteralNumberHex */ 'mh': #6cb6ff,
  /* LiteralNumberInteger */ 'mi': #6cb6ff,
  /* LiteralNumberIntegerLong */ 'il': #6cb6ff,
  /* LiteralNumberOct */ 'mo': #6cb6ff,
  /* Operator */ 'o': #f47067,
  /* OperatorWord */ 'ow': #f47067,
  /* Punctuation */ 'p': $code-color-dark,
  /* Comment */ 'c': #768390,
  /* CommentHashbang */ 'ch': #768390,
  /* CommentMultiline */ 'cm': #768390,
  /* CommentSingle */ 'c1': #768390,
  /* CommentSpecial */ 'cs': #768390,
  /* CommentPreproc */ 'cp': #6cb6ff,
  /* CommentPreprocFile */ 'cpf': #768390,
  /* Generic */ 'g': $code-color-dark,
  /* GenericDeleted */ 'gd': #ffd8d3,
  /* GenericEmph */ 'ge': $code-color-dark,
  /* GenericError */ 'gr': $code-error-color,
  /* GenericHeading */ 'gh': $code-color-dark,
  /* GenericInserted */ 'gi': #b4f1b4,
  /* GenericOutput */ 'go': $code-color-dark,
  /* GenericPrompt */ 'gp': $code-color-dark,
  /* GenericStrong */ 'gs': $code-color-dark,
  /* GenericSubheading */ 'gu': $code-color-dark,
  /* GenericTraceback */ 'gt': $code-error-color,
  /* GenericUnderline */ 'gl': $code-color-dark,
  /* TextWhitespace */ 'w': $code-color-dark,
  /* Other */ 'x': $code-color-dark
) !default;
// ========== Code ========== //

// ========== Admonition ========== //
// Color map of the admonition
$admonition-color-map: (
  'note': #448aff,
  'abstract': #00b0ff,
  'info': #00b8d4,
  'tip': #00bfa5,
  'success': #00c853,
  'question': #64dd17,
  'warning': #ff9100,
  'failure': #ff5252,
  'danger': #ff1744,
  'bug': #f50057,
  'example': #651fff,
  'quote': #9e9e9e
) !default;

// Color map of the admonition background
$admonition-background-color-map: (
  'note': rgba(68, 138, 255, 0.1),
  'abstract': rgba(0, 176, 255, 0.1),
  'info': rgba(0, 184, 212, 0.1),
  'tip': rgba(0, 191, 165, 0.1),
  'success': rgba(0, 200, 83, 0.1),
  'question': rgba(100, 221, 23, 0.1),
  'warning': rgba(255, 145, 0, 0.1),
  'failure': rgba(255, 82, 82, 0.1),
  'danger': rgba(255, 23, 68, 0.1),
  'bug': rgba(245, 0, 87, 0.1),
  'example': rgba(101, 31, 255, 0.1),
  'quote': rgba(159, 159, 159, 0.1)
) !default;
// ========== Admonition ========== //
